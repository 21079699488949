import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import Accordion from "../components/Accordion/Accordion";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import Certifications from "../components/Repeating/Certifications";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

import level01PDF from "../downloads/BellaWoof_Beginner-Obedience_2024_Aug-Sept.pdf";
import level02PDF from "../downloads/BellaWoof-IntermediateClass_2024_July-Sep.pdf";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
      <SearchEngineOptimization
        title="Beginner & Intermediate Classes San Diego | Bella Woof"
        description="Keep your furry friends engaged with Bella Woof’s certified adult dog training in San Diego! Training doesn't end with puppy school. Learn more here."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <div className="container">
        <section className="pt-2 md:pt-20 pb-20 md:pb-28">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-28 items-center">
            <div className="order-2 md:order-1">
              <h1>Certified Dog Classes in San Diego</h1>
              <p>
                Keep your furry friends engaged with Bella Woof's certified Dog Training classes at
                BellaWoof in La Jolla! Training doesn’t end with puppy school and it’s never too
                late for your older pal to learn new tricks. Prepare for continuous learning with
                beginner and intermediate classes for dogs.
              </p>
              <ButtonSolid
                href="tel:+1-619-237-0730"
                text="(619) 237-0730"
                className="gtm-phone-number"
              />
            </div>
            <div className="order-1 md:order-2">
              <Img
                fluid={data.intro.childImageSharp.fluid}
                alt="San Diego Puppy Training Classes"
              />
            </div>
          </div>
        </section>
      </div>

      <Certifications className="mb-20 md:mb-32" />

      <section className="bg-primary_lighter pt-22 pb-20 md:py-28">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-16 md:mb-14">
            <div className="order-2 md:order-1">
              <div className="mb-10 md:mb-14">
                <h2>Beginner Dog Obedience Training</h2>
                <p>
                  Bella Woof's Family Dog Beginner Obedience Classes are every Wednesday at 6:30pm
                  at Bella Woof in La Jolla! Enrollment is limited to 4 dogs, so sign up soon! Adult
                  Classes are progressive and held every Wednesday at 6:30pm at Bella Woof in La
                  Jolla at 7755 Girard Avenue. Download class start days & times below.
                </p>
                <ButtonSolid
                  href="tel:+619-237-0730"
                  className="gtm-phone-number md:mr-6"
                  text="(619) 237-0730"
                />
              </div>

              <p className="font-heading text-gray-900 font-bold text-lg mb-8">
                Register for this class
              </p>
              <p>
                To enroll and pay for class, call Bella Woof at 619-237-0730 via credit card or
                Venmo or stop in our boutique in La Jolla at 7755 Girard Ave. La Jolla, CA 92037.
              </p>
              <ul>
                <li>
                  <a
                    className="flex items-center text-gray-400 hover:text-primary font-bold"
                    href={level01PDF}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 10L12 15L17 10"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15V3"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Download Class Info
                  </a>
                </li>
              </ul>
            </div>
            <div className="order-1 md:order-2">
              <Img
                fluid={data.level1.childImageSharp.fluid}
                alt="Puppy & Intro Adult Dog Training Package"
                className="rounded-3xl"
              />
            </div>
          </div>

          <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-primary text-opacity-30 mb-10 md:mb-12">
            Class Info
          </p>

          <div className="grid md:grid-cols-12 gap-y-8 md:gap-y-10">
            <div className="md:col-start-1 md:col-span-4">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Where:</p>
              <ul className="mb-8">
                <li>Bella Woof</li>
                <li>7755 Girard Ave, La Jolla, CA 92037</li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">When:</p>
              <ul className="mb-8">
                <li>Wednesdays at 6:30pm</li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Sessions:</p>
              <ul className="mb-8">
                <li>4 weekly classes; 1 hour each</li>
                <li>Maximum 2 people per dog</li>
                <li>4 dogs per class maximum</li>
                <li>Dogs 6 months or older</li>
              </ul>

              {/* <p className="font-heading text-gray-900 font-bold text-lg mb-2">Dog Ages:</p>
                     <ul className="mb-8">
                        <li>16 weeks or older</li>
                     </ul> */}

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Price:</p>
              <ul>
                <li>$199 total</li>
              </ul>
            </div>
            <div className="md:col-end-13 md:col-span-8 md:border-l md:border-solid md:border-gray-500 md:border-opacity-30 md:pl-10">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2 md:mb-6">
                Skills covered:
              </p>
              <ul className="styled-list primary mb-8 md:mb-12 grid md:grid-cols-2">
                <div>
                  <li>Sit</li>
                  <li>Down</li>
                  <li>Wait/Stay</li>
                  <li>Recall</li>
                  <li>Leave It</li>
                </div>
                <div>
                  <li>Drop It</li>
                  <li>Loose Leash Walking</li>
                  <li>Heeling</li>
                  <li>Attention to Owner</li>
                  <li>Relaxation</li>
                  <li>
                    Handling for Vets & Groomers, as well as solutions to common behavior problems.
                  </li>
                </div>
              </ul>

              <Accordion className="shadow-primary" title="Prerequisites">
                <ul className="styled-list primary baseline">
                  <li>Dogs must be manageable in presence of other dogs.</li>
                  <li>
                    Dogs must be non-aggressive towards people. Private, in-home training available
                    for reactive dogs.
                  </li>
                  <li>Dogs must be 6 months or older.</li>
                  <li>
                    Private, customized training packages available for reactive dogs and specific
                    behavioral issues.
                  </li>
                </ul>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary_light pt-22 pb-20 md:py-28 mb-20 md:mb-28">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-16 md:mb-14">
            <div className="order-2 md:order-1">
              <div className="mb-14">
                <h2>Intermediate Class- Rocket Recall and Loose Leash Walking</h2>
                <p>
                  Bella Woof's Rocket Recall and Loose Leash Walking Classes are Intermediate
                  Classes, and are progressive and held on Wednesdays at 6:30 pm at Bella Woof in La
                  Jolla! Each month a new Intermediate Class will begin, starting off with Rocket
                  Recall and Loose Leash Walking.
                </p>
                <p>
                  More new Intermediate Class topics are to come as word gets out & classes are
                  developed. Intermediate Class topic suggestions are welcome. Classes are small;
                  enrollment is limited to 4 dogs. Sign up soon! Classes focus on Recall or Loose
                  Leash Walking and are designed for older puppies and adult dogs (6 mo & older).
                </p>
                <p>
                  Rocket Recall & Loose Leash Walking are intermediate-level classes that build on
                  the skills learned in a "positive reinforcement" puppy class or beginner obedience
                  class.
                </p>
              </div>

              <p className="font-heading text-gray-900 font-bold text-lg mb-8">
                Register for this class
              </p>
              <p>
                To enroll and pay for class call Bella Woof at 619-237-0730 via credit card or Venmo
                or stop in our boutique in La Jolla at 7755 Girard Ave. La Jolla, CA 92037.
              </p>
              <ul>
                {/* <li className="flex items-center mb-4">
                           <a className="text-gray-400 hover:text-secondary font-bold" href="tel:+1-619-299-7012">
                              <i className="far fa-phone-alt mr-1 text-3xl text-secondary"></i> (619) 299-7012 x 2398
                           </a>
                        </li> */}
                <li>
                  <a
                    className="flex items-center text-gray-400 hover:text-secondary font-bold"
                    href={level02PDF}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                        stroke="#F89BBD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 10L12 15L17 10"
                        stroke="#F89BBD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15V3"
                        stroke="#F89BBD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Download Class Info
                  </a>
                </li>
              </ul>
            </div>
            <div className="order-1 md:order-2">
              <Img
                fluid={data.level2.childImageSharp.fluid}
                alt="Puppy & Intro Adult Dog Training Package"
                className="rounded-3xl"
              />
            </div>
          </div>

          <p className="text-mobile8xl md:text-8xl leading-none font-heading font-bold text-secondary text-opacity-30 mb-10 md:mb-12">
            Class Info
          </p>

          <div className="grid md:grid-cols-12 gap-y-8 md:gap-y-10">
            <div className="md:col-start-1 md:col-span-4">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Where:</p>
              <ul className="mb-8">
                <li>Bella Woof</li>
                <li>7755 Girard Ave, La Jolla, CA 92037</li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">When:</p>
              <ul className="mb-8">
                <li>Wednesdays at 6:30pm</li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Sessions:</p>
              <ul className="mb-8">
                <li>4 weeks, 1 hour each</li>
                <li>Maximum 4 dogs per class</li>
                <li>Maximum 2 people per dog</li>
                {/* <li>First class is an orientation for owners only.</li> */}
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Dog Ages:</p>
              <ul className="mb-8">
                <li>6 months or older</li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Price:</p>
              <ul>
                <li>$199 total</li>
              </ul>
            </div>
            <div className="md:col-end-13 md:col-span-8 md:border-l md:border-solid md:border-gray-500 md:border-opacity-30 md:pl-10">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Skills covered:</p>
              <p>
                <span className="text-black font-bold">Rocket Recall</span> class focuses
                exclusively on exercises to help you build a reliable and fast Come. Learn how to
                supercharge your recall word, use food and non-food rewards including natural prey
                drive, build attention in distracting environments, and practice recall increasing
                distance and distractions with fun games.
              </p>
              <p>
                <span className="text-black font-bold">Loose Leash Walking</span> class teaches you
                the techniques to enjoy a stress-free walk for both you and your dog. Class includes
                exercises building attention on handler, a casual “loose leash” walk without
                pulling, and a closer “heel” with your dog right next to you. Distractions and
                difficulty are increased through fun games.
              </p>
              <Accordion className="shadow-secondary" title="Prerequisites">
                <p>
                  Private, customized training packages are available for reactive dogs and specific
                  behavioral issues.
                </p>
                <ul className="styled-list secondary baseline">
                  <li>
                    Dogs must have attended a “positive reinforcement” Puppy Class or Beginner
                    Obedience Class.
                  </li>
                  <li>Dogs must be 6 months and older.</li>
                  <li>Dogs must be non-aggressive towards people or other dogs.</li>
                  <li>Dogs must be manageable in the presence of other dogs.</li>
                  <li>
                    Positive reinforcement-based tools and techniques only (no prong, choke, shock
                    collars or retractable leads).
                  </li>
                </ul>
              </Accordion>
              {/* <Accordion className="shadow-secondary" title="Cancellation & No-Show Policy">
                        <p>
                           In deference to other students, those who arrive 10 minutes late to class will not be admitted and will be marked as
                           absent. No class make ups will be given for students who are tardy.
                        </p>
                     </Accordion>
                     <Accordion className="shadow-secondary" title="Refund Policy">
                        <ul className="styled-list secondary baseline mb-6">
                           <li>
                              Classes must be paid for in full at least 72 hours prior to the scheduled start date, including online registrations and
                              manual registrations.
                           </li>
                           <li>If space is still available, class registrations will be accepted until the start of the scheduled class.</li>
                           <li>Refunds and cancellations are offered until 72 hours prior to the class’ scheduled start date.</li>
                           <li>
                              Students must request a cancellation no later than 72 hours prior to the scheduled class start date to receive a refund.
                           </li>
                           <li>Refunds will be processed within one week of student's request for refund/cancellation.</li>
                           <li>No drop-ins, class transfers, or pro-rated class fees.</li>
                        </ul>
                        <p>
                           Classes can be cancelled and/or postponed with no notice. The Humane Society will do their best to alert you if classes are
                           cancelled or rescheduled, so you may adjust your schedule accordingly. You will receive the full number of sessions, but
                           the class end date may be later than anticipated.
                        </p>
                     </Accordion> */}
            </div>
          </div>
        </div>
      </section>

      <WhyUs className="mb-20 md:mb-32" />

      <About className="mb-20 md:mb-32" />

      <TestimonialBackgroundImage />

      <CTABackgroundColor>
        <h2>Train with us!</h2>
        <p>
          Whether you have a puppy or an adult furry friend, we have classes in different locations
          to suit their needs. Get in touch to book training today!
        </p>
      </CTABackgroundColor>

      <InstagramFeed />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(relativePath: { eq: "open-graph/facebook/Adult Dog Classes_FB.jpg" }) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Adult Dog Classes_Twitter.jpg" }
    ) {
      publicURL
    }
    intro: file(relativePath: { eq: "training/adult-dog/1.0-Adult-Training-Intro.png" }) {
      childImageSharp {
        fluid(maxWidth: 1256, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    level1: file(relativePath: { eq: "training/adult-dog/Level-1-Marvelous Manners.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    level2: file(relativePath: { eq: "training/adult-dog/Level 2_ Superb Skills.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Page;
